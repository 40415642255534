import {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Modal, {ModalNames} from '../Modal/Modal';
import Text from '../UI/Text/Text';
import {useStyle} from '../../utils/theme/useStyle';
import {ModalImportProductsRules} from './ModalImportProducts.style';
import {useTranslation} from '../../utils/i18n/hooks/useTranslation';
import {Button} from '../UI/Button/Button';
import {useTheme} from '../../utils/theme/useTheme';
import {Spinner} from '../UI/Spinner/Spinner';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {configState} from '../../redux/slices/configSlice';
import {
  resetUploadedProductsNetworkStatus,
  uploadedProductsState,
  uploadProducts
} from 'src/redux/slices/uploadedProductsSlice';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routing/routeConfig';
import { ImportType } from '@teleport/schemas-protobuf';
import { linkToDownloadTheTemplate } from 'src/utils/constants';

interface IProps {
  active: boolean;
  onClose: () => void;
}

export const ModalImportProducts= memo(function ModalImportProducts(props: IProps) {
  const { active, onClose } = props;
  const { config } = useAppSelector(configState)
  const inputRef = useRef<HTMLInputElement>(null);
  const [footerHeight, setFooterHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);

  const modalFooterRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setFooterHeight(node.clientHeight);
    }
  }, []);
  const modalHeaderRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setHeaderHeight(node.clientHeight);
    }
  }, []);

  const { css } = useStyle(ModalImportProductsRules, {
    headerHeight,
    footerHeight,
  })
  const dispatch = useAppDispatch()

  const { t } = useTranslation();
  const { theme } = useTheme();
  const [invalidFileType, setInvalidFileType] = useState(false)
  const { networkStatus: uploadedNetworkStatus } = useAppSelector(uploadedProductsState)
  const [fileUnitArray, setFileUnitArray] = useState<Uint8Array | undefined>()
  const navigate = useNavigate()

  const onChangeProductFile = useCallback(async (event:  React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; // Получаем первый файл из input
    if (!file) return; // Если файла нет, выходим
    const isValidFile = config.typesProductFile.find(el => file.type.includes(el))
    if (isValidFile) {
      try {
        const arrayBuffer = await file.arrayBuffer();
        const uint8Array = new Uint8Array(arrayBuffer);
        setFileUnitArray(uint8Array)
        dispatch(uploadProducts({
          importType: ImportType.DRY_RUN,
          fileBlob: uint8Array, 
        }));

        setInvalidFileType(false); 
      } catch (error) {
        console.error('Error processing file:', error); 
        setInvalidFileType(true); 
      }
    } else {
      setInvalidFileType(true);
    }
  }, [config.typesProductFile, dispatch]);

  useEffect(() => {
    if (uploadedNetworkStatus === NetworkStatus.Failed && inputRef.current) {
      inputRef.current.value = ''
    }

    if (uploadedNetworkStatus === NetworkStatus.Done && fileUnitArray && inputRef.current) {
      navigate(RoutePath.UploadedProducts, {state: {fileUnitArray}})
      inputRef.current.value = ''
    }
  }, [uploadedNetworkStatus, fileUnitArray, navigate, inputRef])

  useEffect(() => {
    return () => {
      dispatch(resetUploadedProductsNetworkStatus());
    }
  }, [dispatch]);

  const onClickUploadButton = () => {
    const input = inputRef.current;
    input.click();
  }

  const modalContent = useMemo(() => {
    if (uploadedNetworkStatus === NetworkStatus.Loading) {
      return (
        <div className={css.spinnerWrapper}>
          <Spinner/>
        </div>
      )
    }
    return (
      <>
        <div ref={modalHeaderRef} className={css.header}>
          <Text
            text={t('modalImportProducts.importOfGoods')}
            mod="title"
            fontWeight={800}
            fontSize={22}
            lineHeight={26}
          />
        </div>
        <div className={css.modalInner}>
          <input
            name='productFile'
            type='file'
            onChange={onChangeProductFile}
            className={css.input}
            ref={inputRef}
          />
          <Text
            text={t('modalImportProducts.youCanCreateMultipleProductsAtOnceForTheBestResultsDownloadOurTemplateFillItOutAndUploadTheDocumentInCSVOrXMLFormat')}
            mod='text'
            fontWeight={400}
            fontSize={14}
            lineHeight={18}
          />
          {
            invalidFileType
            && <Text
              text={t('modalImportProducts.invalidFileFormat')}
              mod='text'
              fontWeight={500}
              fontSize={14}
              lineHeight={18}
              extend={css.invalidMessage}
            />
          }
          <div className={css.btnWrapper}>
            <Button
              text={t('modalImportProducts.downloadTemplate')}
              propsStyles={{background: theme.colorGrey, color: theme.colorBlack, width: '100%'}}
              hasGradient={false}
              href={linkToDownloadTheTemplate}
              download={true}
              target={'_blank'}
              rel={'noopener noreferrer'}
            />
          </div>
          <div className={css.footer} ref={modalFooterRef}>
            <Button
              propsStyles={{width: '100%'}}
              type="submit"
              text={t('modalImportProducts.uploadAFileWithProducts')}
              onClick={onClickUploadButton}
            />
          </div>
        </div>
      </>
    )
  }, [
    invalidFileType,
    css,
    t,
    theme,
    onChangeProductFile,
    modalFooterRef,
    modalHeaderRef,
    uploadedNetworkStatus
  ])

  return (
    <Modal
      active={active}
      onClose={onClose}
      name={ModalNames.ImportProducts}
      propsStyles={{paddingBottom: 0}}
    >
      {modalContent}
    </Modal>
  );
})
